import React, {useContext, useEffect, useState} from "react";
import {Select, Row, Col, Button} from "antd";

import {AppContext} from "../../contexts/app-context";
import GoogleAndroidAppLink from "../google-android-app-link";
// import YoutubeVideoLink from "../youtube-video-link";
// import SelectField from "../select-field";
import Cookies from 'js-cookie';
import {useLocation} from "react-router-dom";

export default function TopFilter() {
  const {Option} = Select;
  const appContext = useContext(AppContext);
  const [clearFilterFlag, setClearFilterFlag] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const path = useLocation().pathname.split("/").slice(-1);
  const {regionsLoadings, regionsUnloadings} = appContext.filter;

  const getCookies = (name) => {
    return Cookies.get(`${name}-${path}`) ? Cookies.get(`${name}-${path}`).split(",") : [];
  }

  const [selectedRegionsLoading, setSelectedRegionsLoading] = useState(getCookies("regionsLoading"));
  const [selectedRegionsUnloading, setSelectedRegionsUnloading] = useState(getCookies("regionsUnloading"));
  const [selectedSorting, setSelectedSorting] = useState(appContext.filter.selectedSorting);

  const isEmptyFilter = !Boolean(selectedRegionsLoading.length) && !Boolean(selectedRegionsUnloading.length) && selectedSorting === 'default';

  const onChangeRegionLoading = (values) => {
    setSelectedRegionsLoading(values);
  };
  const onChangeRegionUnloading = (values) => {
    setSelectedRegionsUnloading(values);
  };
  const onChangeFilterSort = (value) => {
    setSelectedSorting(value);
  };

  const setCookies = (name, values) => {
    if (values.length) {
      Cookies.set(`${name}-${path}`, values.join());
    } else if (Cookies.get(`${name}-${path}`)) {
      Cookies.remove(`${name}-${path}`);
    }
  }

  useEffect(() => {
    if (!isFiltered && !isEmptyFilter || clearFilterFlag) {
      appContext.applyFilterParams(selectedRegionsLoading, selectedRegionsUnloading, selectedSorting);
      if (!isFiltered && !isEmptyFilter) {
        setIsFiltered(true);
        setClearFilterFlag(true);
      }
    }
  }, [isFiltered, clearFilterFlag]);

  const applyFilterParams = () => {
    appContext.applyFilterParams(selectedRegionsLoading, selectedRegionsUnloading, selectedSorting);
    if (!isEmptyFilter) {
      setIsFiltered(true);
      setClearFilterFlag(true);
    }
    setCookies('regionsLoading', selectedRegionsLoading);
    setCookies('regionsUnloading', selectedRegionsUnloading);
  };

  const clearFilterParams = () => {
    setSelectedRegionsUnloading([]);
    setSelectedRegionsLoading([]);
    setSelectedSorting('default');

    setCookies('regionsLoading', []);
    setCookies('regionsUnloading', []);
    if (isFiltered) {
      setIsFiltered(false);
    }
  };

  const sortItems = appContext.filter.sort;
  const applyGoogleAndroidAppLink = appContext.user.isGuest();

  return (
    <div className="filters-container">
      <div className="container">
        <div className="filters-container__wrapper">
          <Row gutter={22} style={{padding: "0", margin: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col className="first-filter" xs={12} sm={12} md={8} lg={8} xl={8} style={{}}>
              <Select
                mode="multiple"
                size="default"
                optionFilterProp="children"
                defaultValue={selectedRegionsLoading}
                value={selectedRegionsLoading}
                onChange={(value) => {
                  onChangeRegionLoading(value);
                }}
                showArrow={true}
                notFoundContent={<p className="b-not-fount-text">Отсутствуют регионы загрузки</p>}
                placeholder="Выберите регион загрузки"
                style={{width: "100%", height: "100%"}}
              >
                {regionsLoadings.map((regionLoad) => {
                  return <Option key={regionLoad.id}>{regionLoad.title}</Option>;
                })}
              </Select>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8} style={{}}>
              <Select
                mode="multiple"
                size="default"
                optionFilterProp="children"
                defaultValue={selectedRegionsUnloading}
                value={selectedRegionsUnloading}
                onChange={(value) => {
                  onChangeRegionUnloading(value);
                }}
                showArrow={true}
                notFoundContent={<p className="b-not-fount-text">Отсутствуют регионы разгрузки</p>}
                placeholder="Выберите регион разгрузки"
                style={{width: "100%", height: "100%"}}
              >
                {regionsUnloadings.map((regionUnload) => {
                  return <Option key={regionUnload.id}>{regionUnload.title}</Option>;
                })}
              </Select>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5} xl={5} style={{}}>
              <Select
                size="default"
                onChange={(value) => {
                  onChangeFilterSort(value);
                }}
                defaultValue={selectedSorting}
                value={selectedSorting}
                style={{width: "100%", height: "100%"}}
              >
                {sortItems.map((sortItem) => {
                  return (
                    <Option key={sortItem.name} value={sortItem.name} selected={sortItem.selected}>
                      {sortItem.title}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col className="filters-buttons" xs={12} sm={12} md={3} lg={3} xl={3} style={{}}>
              <Button
                className="filters-button"
                type="primary"
                size="default"
                icon="search"
                htmlType="button"
                title="Применить параметры фильтра"
                onClick={applyFilterParams}
              />
              <Button
                className="filters-button filters-button--clear"
                type="primary"
                size="default"
                icon="close"
                disabled={isEmptyFilter}
                htmlType="button"
                title="Очистить параметры фильтра"
                style={{border: "none", outline: "none", marginLeft: "5px"}}
                onClick={clearFilterParams}
              />
            </Col>
          </Row>
        </div>
      </div>
      {/*<YoutubeVideoLink />*/}
      {applyGoogleAndroidAppLink ? <GoogleAndroidAppLink/> : null}
    </div>
  );
}
